<template>
  <div class="card">
    <div class="card-body pb-1 row">
      <div class="col-12">
        <h5 class="card-title">
          <span>สรุป</span>
          <ListReportPurchaseSummaryCsv
            class="float-right"
            :docType="docType"
            :startDate="startDate"
            :endDate="endDate"
            :items="rows">
          </ListReportPurchaseSummaryCsv>
        </h5>
      </div>

      <div class="col-12">
        <sgv-table
          headerless
          :items="rows"
          :headers="headers"
          :options.sync="options">

          <template slot-scope="{item, hidden}">
            <tr>
              <td v-if="hidden.type">
                {{item.type}}
              </td>
              <td v-if="hidden.totalPrice" class="text-right">
                {{item.totalPrice | comma}}
              </td>
            </tr>
          </template>
        </sgv-table>
      </div>
    </div>
  </div>
</template>

<script>
import ListReportPurchaseSummaryCsv from './ListReportPurchaseSummaryCsv'

export default {
  components: {
    ListReportPurchaseSummaryCsv
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required:false
    },
    endDate: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {text: 'ประเภท', value: 'type'},
        {text: 'มูลค่า', value: 'totalPrice'},
      ],
      options: {
        headers: ['type', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  computed: {
    rows () {
      return [
        {type: 'สินค้า', totalPrice: this.totalPriceByType('item')},
        {type: 'สินค้าบริการ', totalPrice: this.totalPriceByType('service')},
        {type: 'ค่าใช้จ่าย', totalPrice: this.totalPriceByType('expense')},
        {type: 'ส่วนลด', totalPrice: this.totalPriceByType('discountPrice')},
        {type: 'ภาษีมูลค่าเพิ่ม', totalPrice: this.totalPriceByType('vatPrice')},
        {type: 'ยอดรวม', totalPrice: this.totalPriceByType('netPrice')}
      ]
    }
  },
  methods: {
    totalPriceByType (type) {
      return this.items.find(v => v.type === type)?.totalPrice || '0'
    }
  }
}
</script>

<style lang="css" scoped>
</style>
