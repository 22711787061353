<template>
  <div class="card">
    <div class="card-body pb-1 row">
      <div class="col-12">
        <h5 class="">
          <span>สรุปสินค้า</span>
          <ListReportInvoiceSummaryInventoryCsv
            class="float-right"
            :docType="docType"
            :startDate="startDate"
            :endDate="endDate"
            :items="items">
          </ListReportInvoiceSummaryInventoryCsv>
        </h5>
      </div>

      <div class="col-12 mb-3">
        ยอดรวม:
        <span class="text-warning">
          {{netAmount | comma}}
        </span>
      </div>

      <div class="col-12">
        <sgv-table
          headerless
          :items="items"
          :headers="headers"
          :options.sync="options">

          <template slot-scope="{item, hidden}">
            <tr>
              <td v-if="hidden.type">
                <span v-if="item.type === 'item'" class="text-warning">สินค้า</span>
                <span v-else-if="item.type === 'service'" class="text-info">สินค้าบริการ</span>
              </td>
              <td v-if="hidden.inventoryId">
                {{item.inventory.code}}
                <div class="text-primary">
                  <small>{{item.inventory.name}}</small>
                </div>
              </td>
              <td v-if="hidden.qty" class="text-right">
                {{item.qty | comma}} {{item.inventory.unit.name}}
                <small>
                  <div class="text-info">({{price(item) | comma}})</div>
                </small>
              </td>
              <td v-if="hidden.totalPrice" class="text-right">
                {{item.totalPrice | comma}}
              </td>
            </tr>
          </template>
        </sgv-table>
      </div>
    </div>
  </div>
</template>

<script>
import ListReportInvoiceSummaryInventoryCsv from './ListReportInvoiceSummaryInventoryCsv'

export default {
  components: {
    ListReportInvoiceSummaryInventoryCsv
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required:false
    },
    endDate: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {text: 'ประเภท', value: 'type'},
        {text: 'สินค้า', value: 'inventoryId'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'มูลค่า', value: 'totalPrice'},
      ],
      options: {
        headers: ['type', 'inventoryId', 'qty', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  computed: {
    netAmount () {
      return this.items.reduce((t,v) => t + +v.totalPrice, 0)
    },
  },
  methods: {
    price (item) {
      if (!item.qty) return item.totalPrice
      return item.totalPrice / item.qty
    }
  }
}
</script>

<style lang="css" scoped>
</style>
