<template>
  <sgv-csv
    :items="csvData"
    :labels="csvLabels"
    :filename="csvFilename">
    <button
      :disabled="csvData.length === 0"
      type="button"
      class="btn btn-success form-group btn-sm">
      EXCEL
    </button>
  </sgv-csv>
</template>

<script>
export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required:false
    },
    endDate: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      csvLabels: {
        id: { title: 'ลำดับ' },
        type: { title: 'ประเภท' },
        inventoryCode: { title: 'รหัส' },
        inventoryName: { title: 'ชื่อ' },
        inventoryUnit: { title: 'หน่วย' },
        qty: { title: 'จำนวน' },
        price: { title: 'ราคา/หน่วย' },
        totalPrice: { title: 'ยอดรวม' },
      },
      csvFilename: `${this.docType}-สั่งซื้อ-สรุปสินค้า-${this.startDate}-to-${this.endDate}`
    }
  },
  computed: {
    csvData () {
      return this.items.map((v,idx) => {
        return {
          id: idx+1,
          type: this.getTypeTxt(v.type),
          inventoryCode: v.inventory.code,
          inventoryName: v.inventory.name,
          inventoryUnit: v.inventory.unit.name,
          qty: v.qty,
          price: this.$form.round(this.price(v)),
          totalPrice: v.totalPrice,
        }
      })
    }
  },
  methods: {
    getTypeTxt (type) {
      if (type === 'item') return 'สินค้า'
      else if (type === 'service') return 'บริการ'
    },
    price (item) {
      if (!item.qty) return item.totalPrice
      return item.totalPrice / item.qty
    }
  }
}
</script>

<style lang="css" scoped>
</style>
