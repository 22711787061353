<template>
  <div class="card">
    <div class="card-body pb-1">
      <div class="card-title">
        <h5 class="">
          <span>รายเอกสาร</span>
          <ListReportPurchaseDocCsv
            class="float-right"
            :docType="docType"
            :startDate="startDate"
            :endDate="endDate"
            :items="items">
          </ListReportPurchaseDocCsv>
        </h5>
      </div>
      <div class="mb-3">
        ยอดรวม:
        <span class="text-warning">
          {{netAmount | comma}}
        </span>
      </div>

      <sgv-table
        headerless
        :items="items"
        :headers="headers"
        :options.sync="options">

        <template slot-scope="{item, hidden}">
          <tr>
            <td v-if="hidden.id">
              {{item.doc.approvedAt | date}}
            </td>
            <td v-if="hidden.doc">
              <router-link
                class="text-decoration-none"
                :class="{
                  'text-warning': !item.doc.closedAt,
                  'text-success': item.doc.closedAt,
                }"
                :to="toDoc(item)">
                {{item.doc.code}}
              </router-link>
              <small>
                <div class="text-primary">{{item.doc.name}}</div>
                <div class="text-info" v-if="item.doc.remark">
                  {{item.doc.remark}}
                </div>
              </small>
            </td>
            <td v-if="hidden.totalPrice" class="text-right">
              {{item.totalPrice | comma}}
            </td>
          </tr>
        </template>
      </sgv-table>
    </div>
  </div>
</template>

<script>
import ListReportPurchaseDocCsv from './ListReportPurchaseDocCsv.vue'

export default {
  components: {
    ListReportPurchaseDocCsv
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required:false
    },
    endDate: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {text: 'วันที่อนุมัติ', value: 'id'},
        {text: 'เอกสาร', value: 'doc'},
        {text: 'มูลค่า', value: 'totalPrice'},
      ],
      options: {
        headers: ['id', 'doc', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  computed: {
    netAmount () {
      return this.items.reduce((t,v) => t + +v.totalPrice, 0)
    },
  },
  methods: {
    toDoc (item) {
      return {
        name: `Doc${this.$form.capitalize(item.doc.type)}Detail`,
        params: {docId: item.doc.id}
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
