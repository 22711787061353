<template>
  <sgv-csv
    :items="csvData"
    :labels="csvLabels"
    :filename="csvFilename">
    <button
      :disabled="csvData.length === 0"
      type="button"
      class="btn btn-success form-group btn-sm">
      EXCEL
    </button>
  </sgv-csv>
</template>

<script>
export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required:false
    },
    endDate: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      csvLabels: {
        id: { title: 'ลำดับ' },
        type: { title: 'ประเภท' },
        totalPrice: { title: 'ยอดรวม' },
      },
      csvFilename: `${this.docType}-จัดส่ง-สรุป-${this.startDate}-to-${this.endDate}`
    }
  },
  computed: {
    csvData () {
      return this.items.map((v,idx) => {
        return {
          id: idx+1,
          type: v.type,
          totalPrice: v.totalPrice,
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
