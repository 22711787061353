<template>
  <div class="card">
    <div class="card-body pb-1 row">
      <div class="col-12">
        <h5 class="card-title">
          <span>รายเอกสาร-สินค้า</span>
          <ListReportPurchaseDocInventoryCsv
            class="float-right"
            :docType="docType"
            :startDate="startDate"
            :endDate="endDate"
            :items="items">
          </ListReportPurchaseDocInventoryCsv>
        </h5>
      </div>

      <div class="col-12 mb-3">
        ยอดรวม:
        <span class="text-warning">
          {{netAmount | comma}}
        </span>
      </div>

      <div class="col-12">
        <sgv-table
          headerless
          :items="items"
          :headers="headers"
          :options.sync="options">

          <template slot-scope="{item, hidden}">
            <tr>
              <td v-if="hidden.id">
                {{item.doc.approvedAt | date}}
              </td>
              <td v-if="hidden.doc">
                <router-link
                  class="text-decoration-none"
                  :class="{
                    'text-warning': !item.doc.closedAt,
                    'text-success': item.doc.closedAt,
                  }"
                  :to="toDoc(item)">
                  {{item.doc.code}}
                </router-link>
                <small>
                  <div class="text-primary">{{item.doc.name}}</div>
                  <div class="text-info" v-if="item.doc.remark">
                    {{item.doc.remark}}
                  </div>
                </small>
              </td>
              <td v-if="hidden.inventory">
                {{item.inventory.code}}
                <small>
                  <div class="text-primary">{{item.inventory.name}}</div>
                </small>
              </td>
              <td v-if="hidden.qty" class="text-right">
                {{item.qty | comma}} {{item.inventory.unit.name}}
                <small>
                  <div class="text-info">({{price(item) | comma}})</div>
                </small>
              </td>
              <td v-if="hidden.totalPrice" class="text-right">
                {{item.totalPrice | comma}}
              </td>
            </tr>
          </template>
        </sgv-table>
      </div>
    </div>
  </div>
</template>

<script>
import ListReportPurchaseDocInventoryCsv from './ListReportPurchaseDocInventoryCsv'


export default {
  components: {
    ListReportPurchaseDocInventoryCsv
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required:false
    },
    endDate: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {text: 'วันที่อนุมัติ', value: 'id'},
        {text: 'เอกสาร', value: 'doc'},
        {text: 'สินค้า', value: 'inventory'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'มูลค่า', value: 'totalPrice'},
      ],
      options: {
        headers: ['id', 'doc', 'inventory', 'qty', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  computed: {
    netAmount () {
      return this.items.reduce((t,v) => t + +v.totalPrice, 0)
    },
  },
  methods: {
    toDoc (item) {
      return {
        name: `Doc${this.$form.capitalize(item.doc.type)}Detail`,
        params: {docId: item.doc.id}
      }
    },
    price (item) {
      if (!item.qty) return item.totalPrice
      return item.totalPrice / item.qty
    }
  }
}
</script>

<style lang="css" scoped>
</style>
